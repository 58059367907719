:root {
  --primary: #273576;
  --secondary: #eef5f9;
  --blue: #2576d2;
  --gray: #e9ecef;
  --gray-dark: #495057;

  --bitys-green: #7fc34d;
  --bitys-blue: #287900;

  --bg-color: #f1f4f6;
  --bg-logo: rgba(250, 251, 252, 0);
  --bg-menu-active: #343a40;
  --bg-header: #684fa9;
  --bg-menu: #684fa9;
  --color-menu: #fff;
  --color-menu-dest: #343a40;
}

/* Layout */

.app-header__logo .logo-src {
  height: 45px !important;
}

.ql-editor {
  min-height: 300px !important;
}
.ql-snow .ql-tooltip {
  left: 0 !important;
}
.hidden {
  display: none;
}
/* .ReactCrop__crop-selection {
  
} */

.cropProfile :nth-child(2) {
  border-radius: 50%;
}

/* Gravador de áudio */
._1ceqH ._1lB9c,
._1Yplu {
  display: none !important;
}
._2fG9h {
  padding: 0 !important;
}
._eV_dK {
  text-align: center !important;
}
.basic-multi-select {
  width: 100%;
}
